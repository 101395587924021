
import { IonPage, IonContent, toastController, loadingController, alertController, IonBackButton, IonToolbar, IonInput, IonSpinner, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue';
import { defineComponent, onMounted, reactive, } from 'vue';
import { useRouter } from 'vue-router';
import Empty from '../../../../components/Empty.vue';

/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: { IonPage, IonContent, IonBackButton, IonToolbar, IonInput, IonSpinner,Empty, IonInfiniteScroll, IonInfiniteScrollContent },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props){
    const router = useRouter();
    const state = reactive({
      comment:{
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1
      },
      form:{
        content:''
      },
      contentLoading: true,
    });

    const openToast = async (message: string) => {
      const toast = await toastController
        .create({
          message: message,
          duration: 2000
        })
      return toast.present();
    };

    const presentAlert = async (message: string) => {
      const alert = await alertController
        .create({
          header: "알림",
          message: message,
          buttons: ['확인'],
        });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    };

    const fetch = async (event: any = null, loadMore = false) => {
      state.comment.loading = true;
      const params = {
          size: state.comment.size,
          page: state.comment.page
        }
      await services.commentPostFindone(props.id, params).then(
         response => {
            const { data } = response;
            data.items.some((item: object) => {
            state.comment.items.push(item);
          });
            state.comment.totalItems = data.totalItems;
            state.comment.totalPages = data.totalPages;
            state.comment.loading = false;
            state.comment.page++;
            if ( event ) {
              if ( data.items.length == 0 && loadMore ) {
                event.target.disabled = true;
              }
          }
         },
         error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }
          console.log(message);
           if ( event ) {
            event.target.disabled = true;
          }
          state.comment.totalItems = 0;
        }
      );
      setTimeout(()=>{
        if ( event ) {
          event.target.complete();
        }

        state.contentLoading = false
      } ,500)
    }

    const onSubmit = async() => {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: '등록 중입니다.',
      });
      await loading.present();

      const params = {
        content : state.form.content,
        postId : props.id
      };
      services.commentAdd(params)
      .then(response => {
        console.log(response);
        state.form.content = '';

        if (response.status == 200) {
          setTimeout(function() {
            loading.dismiss()
            fetch();
          }, 1000);
        } else {
          setTimeout(function() {
            loading.dismiss()
          }, 1000);
        }
      })
      .catch(error => {
        let message = '';
        try {
          message = error.response.data.message;
        } catch (e) {
          message = '';
          console.log(e);
        }
        setTimeout(function() {
          loading.dismiss()
          presentAlert(message);
        }, 1000);
      });
    };

    const onRemoveComment = async(id: number) => {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: '삭제 중입니다.',
      });
      await loading.present();

      // const params = {
      //   postId: item.id
      // };
      services.commentRemove(id)
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          setTimeout(function() {
            loading.dismiss()
            fetch();
          }, 1000);
        } else {
          setTimeout(function() {
            loading.dismiss()
          }, 1000);
        }
      })
      .catch(error => {
        let message = '';
        try {
          message = error.response.data.message;
        } catch (e) {
          message = '';
          console.log(e);
        }
        setTimeout(function() {
          loading.dismiss()
          presentAlert(message);
        }, 1000);
      });
    }

    const onLoadData = (event: any) => {
    setTimeout(() => {
      fetch(event, true);
    }, 500)
  };


    // const onUserLink = (id: number)
    const onUserLink = (id: number) => {
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    }

    onMounted(() => {
      state.comment.items = [];
      fetch();
      console.log('onMounted');
    })

    return{state, onSubmit, onUserLink, onRemoveComment, onLoadData }
  }
})
